/* eslint-disable jsx-a11y/label-has-for, no-alert, prettier/prettier, react/no-unused-state, react/jsx-no-undef, no-dupe-keys, no-undef, react/prop-types, no-unused-vars */
import React, { Component } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { css } from '@emotion/react';
import 'react-circular-progressbar/dist/styles.css';
import { Parallax } from 'react-parallax';
import PageTransition from '../components/PageTransition/index';
import '../style/circularProgressBar.css';
import { Divider5, HorizontalLine } from '../components/Dividers';
import HiddenAnchorLink from '../style/HiddenAnchorLink';
import colors from '../utils/colors';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../style/basicStyle';
import media from '../utils/media';
import Hero from '../components/Hero';
import ContactUs from '../components/contactUs';
import '../style/tableStyle.css'; // import this globally. added for styling hidden columns.
import '../style/table.css'; // global table style that pre-dates the hidden column support.

const heading1CustomCSS = css`
  width: 68px;
  height: 68px;
  padding-top: 3px;
  padding-right: 2px;
  font: 68px Times New Roman, sans-serif;
  margin-bottom: 20px;
  background: #0a0833;
  color: ${colors.primary};
  text-align: center;
  padding-left: 0px;
  font-weight: 100;
  margin-top: 10px;
  ${media.mid`
    font-size: 88px;
    width: 88px;
    height: 88px;
    padding-top: 4px;
    padding-right: 2px;
    margin-bottom: 25px;
  `};
`;

const heading2CustomCSS = css`
  font-family: initial;
  font-weight: 200;
  color: ${colors.secondary};
`;

const heading1ImageCSS = css`
  background-color: transparent;
  color: transparent;
  width: 200px;
  margin-top: -200px;
  ${media.small`
    width: 200px;
    width: 200px;
    margin-top: -150px;
  `};
  ${media.mid`
    width: 250px;
    height: 250px;
    margin-top: 0px;
  `};
  ${media.large`
    width: 300px;
    height: 300px;
    margin-top: 0px;
  `};
`;

class Index extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: false });
  }

  render() {
    const { props } = this;
    if (props.errors) {
      props.errors.forEach(err => console.log('index error rendering: ', err));
      throw new Error(props.errors[0] && props.errors[0].message);
    }

    const { imageSharp } = props.data;
    const imageSrc = imageSharp && imageSharp.gatsbyImageDataFullWidth && imageSharp.gatsbyImageDataFullWidth.images && imageSharp.gatsbyImageDataFullWidth.images.fallback && imageSharp.gatsbyImageDataFullWidth.images.fallback.src;

    const pageImages = props.data.pageImages.edges;
    const { placeholderImage } = props.data;

    const placeholderImageSrc = placeholderImage && placeholderImage.gatsbyImageDataFullWidth && placeholderImage.gatsbyImageDataFullWidth.images && placeholderImage.gatsbyImageDataFullWidth.images.fallback && placeholderImage.gatsbyImageDataFullWidth.images.fallback.src;

    const pageData = props.data.pageData.edges[0].node;
    return (
      <PageTransition location={props.location}>
        <Hero
          toggleNavDark={props.toggleNavDark}
          navDarkActive={props.navDarkActive}
          opacity={0.7}
          pageImages={pageImages}
          placeholderImage={placeholderImageSrc}
          heading1Image="bulldogsLogoNew"
          heading1CustomCSS={heading1CustomCSS}
          heading1ImageCSS={heading1ImageCSS}
          heading2={pageData.title}
          heading2CustomCSS={heading2CustomCSS}
          heading3={pageData.subtitle}
          heading1Image-data-aos="zoom-in"
          heading1Image-data-aos-easing="ease-in-out-cubic"
          heading1Image-data-aos-duration="1500"
          heading3-data-aos="fade-in"
          heading3-data-aos-delay="750"
          heading3-data-aos-duration="2000"
          heroImage={imageSrc}
          heading2Underline="enabled"
          minHeight="100vh"
          showScrollDown
        />
        {/* ********************************************************************************** */}
        {/* ********************************* What we do Begin*********************************** */}
        {/* ********************************************************************************** */}
        <HiddenAnchorLink id="bulldogsintro" />
        <Parallax>
          <Flex textAlign="center" justifyContent="center" wrap={['wrap']}>
            <Box mx={[2]} my={[5]} textAlign="center" width={[1, 860]}>
              <Container>
                <Box id="bulldogsintro" data-aos="fade-up" data-aos-duration="2000" width={[1]} textAlign="center" fontSize={[4, 4, 5, 6]}>
                  Are you ready for the future of youth hockey development to be taken to a whole new level?
                </Box>
                <Container width={[1]} p={[1]}>
                  <HorizontalLine
                    id="bulldogsintrohorizontalline"
                    width={[1 / 2, 1 / 4, 1 / 6]}
                    my={[1, 2]}
                    borderColor={colors.tertiary}
                    border={['solid 0.5px']}
                    data-aos-anchor="#bulldogsintro"
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                  />
                </Container>
                <Box p={[3]} textAlign="left" width={[1]} data-aos-anchor="#bulldogsintrohorizontalline" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                  <Box p={[1, 2]}>
                    Shawn Ray and the Bulldog program have been successfully developing youth hockey players in the State of Florida for a number of years.
                    <br />
                    <br />
                    The teams have travelled and successfully competed in many tournaments both nationally and internationally providing both players and their families with memories that will last forever.
                    <br />
                    <br />
                    Shawns vision about player development and his goals for the program are always progressive so his players will be ready and prepared for the constant evolution of the sport.
                    <br />
                    <br />
                    The opportunities that hockey provides are endless and his new vision for the program is going to be a game changer with helping the Florida youth hockey community reach their dreams.
                    <br />
                    <br />
                    <Box py={[2]} fontSize={[3, 4, 4]} style={{ display: 'inline-block', backgroundColor: colors.tertiary}}>
                      Our goal is to help you reach yours!
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Flex>
        </Parallax>
        <ContactUs />
      </PageTransition>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query contentQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      gatsbyImageDataFullWidth: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
    imageSharp(original: { src: { regex: "/indexHero/" } }) {
      id
      original {
        width
        height
        src
      }
      gatsbyImageDataFullWidth: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
    pageImages: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "index" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageDataFullWidth: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
        }
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "index" } }) {
      edges {
        node {
          name
          title
          subtitle
        }
      }
    }
  }
`;
